import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

const emojiList = [
  "\\(^\u0414^)/",
  "(\u0387.\u0387)",
  "(\u02da\u0394\u02da)b",
  "(\u0387_\u0387)",
  "(^_^)b",
  "(>_<)",
  "(o^^)o",
  "(;-;)",
  "(\u2265o\u2264)",
  "\\(o_o)/",
  "(^-^*)",
  "(='X'=)",
];

const NotFoundPage = () => {

  const emojiIndex = Math.floor(Math.random() * emojiList.length);
  const emoji = emojiList[emojiIndex];

  return (
    <Layout>
      <SEO title="404: Not Found" />
      <h1>404: Not Found</h1>
      <div
        style={{
          position: `absolute`,
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`,
        }}
      >
        <div
          style={{
            lineHeight: "150px",
            color: `#dadce0`,
            fontSize: `150px`,
          }}
        >
          {emoji}
        </div>
        <div
          style={{
            lineHeight: `21px`,
            marginTop: `60px`,
            whiteSpace: `pre-wrap`,
            textAlign: `center`,
          }}
        >
          "Unfortunately, this page doesn't exist."
        </div>
      </div>
      <p>
        <Link to="/">Go home</Link>
      </p>
    </Layout>
  );
};

export default NotFoundPage;
